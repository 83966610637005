import { AxiosRequestConfig } from 'axios';
import { clientPortalApi } from 'src/api';
import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import {
  UserDetailResponse,
  CurrentUserResponse,
  GetUserAuthEventsResponse,
  GetUserDetailsResponseDto,
  CreateUserModel,
  CheckUserModel,
  UpdateUserModel,
  UserCompanyModel,
  UserSearchPagedResponse,
  AddClientPortalModel,
  CreateSupportUserModel,
  ClientPortalRole,
  UserStatus,
} from 'src/types';

export const getUserById = (userId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<UserDetailResponse>(`/api/v1/users/${userId}`, config);
export const getUserAuthEventsById = (
  userId: string,
  params: TanstackTableSearchParamsAdapter,
  config: AxiosRequestConfig = {},
) => clientPortalApi.get<GetUserAuthEventsResponse>(`/api/v1/users/${userId}/authEvents`, { ...config, params });
export const getIdentityUserDetailsById = (userId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<GetUserDetailsResponseDto>(`/api/v1/users/${userId}/userDetails`, config);
export const createUser = (data: CreateUserModel) => clientPortalApi.post<string>('/api/v1/users', data);
export const createSupportUser = (data: CreateSupportUserModel) =>
  clientPortalApi.post<string>('/api/v1/users/support-role', data);
export const createUserCheck = (data: CheckUserModel) =>
  clientPortalApi.post<string>('/api/v1/users/email-check', data);
export const updateUser = (data: UpdateUserModel) => clientPortalApi.patch<void>('/api/v1/users', data);
export const assignUserClientPortalRole = (data: AddClientPortalModel) =>
  clientPortalApi.post<void>('/api/v1/users/assignRole', data);
export const updateUserClientPortalRole = (data: AddClientPortalModel) =>
  clientPortalApi.patch<void>('/api/v1/users/updateUserRole', data);

export const getCurrentUser = () => clientPortalApi.get<CurrentUserResponse>('/api/v1/users/current');
export const activateCurrentUser = () => clientPortalApi.post<void>('/api/v1/users/activateCurrent');

export type UserListSearchParams = TanstackTableSearchParamsAdapter & {
  role?: ClientPortalRole;
  status?: UserStatus;
  hasSFTPAccess?: boolean;
};
export const getUserListSearch = (params: UserListSearchParams, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<UserSearchPagedResponse>('/api/v1/users/search', { ...config, params });

export const assignUserToCompany = (data: UserCompanyModel) =>
  clientPortalApi.patch<void>('/api/v1/users/addUserToCompany', data);
export const removeUserFromCompany = (data: UserCompanyModel) =>
  clientPortalApi.patch<void>('/api/v1/users/removeUserFromCompany', data);

export const activateUser = (userId: string) => clientPortalApi.post<void>(`/api/v1/users/${userId}/activate`);
export const deactivateUser = (userId: string) => clientPortalApi.post<void>(`/api/v1/users/${userId}/deactivate`);
export const resendInvitationEmail = (userId: string) =>
  clientPortalApi.post<void>(`/api/v1/users/${userId}/resendConfirmationCode`);
export const resetUserMFA = (userId: string) => clientPortalApi.post<void>(`/api/v1/users/${userId}/reset-mfa`);
export const resendTemporaryPassword = (userId: string) =>
  clientPortalApi.post<void>(`/api/v1/users/${userId}/resendTemporaryPassword`);
