import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'src/store';
import { setPreviousLocation } from 'src/store/slices/routerSlice';

import { history } from 'src/router';
import { Action } from 'history';

const usePreviousLocationInStore = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const prevLocationRef = useRef(location);

  useLayoutEffect(() => {
    if (history.action !== Action.Replace) {
      dispatch(setPreviousLocation(prevLocationRef.current));
    }
    prevLocationRef.current = location;
  }, [dispatch, location]);
};

export default usePreviousLocationInStore;
