import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { getDataRetentionAvailableProductList } from 'src/api/clientPortal/dataRetention';

type DataRetentionState = {
  canCreatePolicy: boolean;
  isLoading: boolean;
};

const initialState: DataRetentionState = {
  canCreatePolicy: false,
  isLoading: true,
};

export const fetchDataRetentionAvailableProduct = createAsyncThunk(
  'dataRetention/fetchDataRetentionAvailableProduct',
  async (companyId: string) => {
    const res = await getDataRetentionAvailableProductList({ companyId });
    return res.data;
  },
);

const dataRetentionSlice = createSlice({
  name: 'dataRetention',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDataRetentionAvailableProduct.pending, (state: DataRetentionState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDataRetentionAvailableProduct.fulfilled, (state: DataRetentionState, action) => {
      state.isLoading = false;
      state.canCreatePolicy = Boolean(action.payload.length);
    });
    builder.addCase(fetchDataRetentionAvailableProduct.rejected, (state: DataRetentionState) => {
      state.isLoading = false;
    });
    // Cleanup
    builder.addCase(resetStore, (state: DataRetentionState) => {
      Object.assign(state, initialState);
    });
  },
});

export default dataRetentionSlice.reducer;
