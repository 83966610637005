import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, ref, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessage } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import AuthLayout from 'src/components/layout/AuthLayout';

import { changeCurrentUserPassword } from 'src/api/auth/user';

import { ChangeCurrentUserPassword, ServerError } from 'src/types';

type FormData = ChangeCurrentUserPassword & {
  newPasswordConfirmation: string;
};

const formSchema: ObjectSchema<FormData> = object({
  oldPassword: string().label('Password').trim().required(),
  newPassword: string().password(),
  newPasswordConfirmation: string()
    .label('Password')
    .trim()
    .required()
    .oneOf([ref('newPassword')], 'Passwords should be the same'),
});

function ChangePassword() {
  const navigate = useNavigate();
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const { register, control, handleSubmit, formState } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = handleSubmit(async ({ newPasswordConfirmation, ...payload }) => {
    try {
      await changeCurrentUserPassword(payload);
      navigate(RoutePath.changePasswordCompleted);
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessage(formErrors[0]);
    }
  });

  return (
    <AuthLayout>
      <h2 className="is-size-2 has-text-centered mb-6">Change Password</h2>
      <form name="changePasswordForm" onSubmit={onSubmit} noValidate>
        <Field
          label="Current Password"
          field="input"
          type="password"
          placeholder="Enter your current password"
          autoComplete="current-password"
          register={register('oldPassword')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          autoFocus
        />

        <Field
          label="New Password"
          field="input"
          type="password"
          placeholder="Enter your new password"
          autoComplete="new-password"
          register={register('newPassword')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />

        <Field
          label="Confirm New Password"
          field="input"
          type="password"
          placeholder="Confirm your new password"
          autoComplete="off"
          register={register('newPasswordConfirmation')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />

        <div className="field pt-4">
          <LoadingButton className="button is-interact is-fullwidth" type="submit" isLoading={formState.isSubmitting}>
            Change password
          </LoadingButton>
          <ServerErrorMessage message={serverErrorMessage} />
        </div>
      </form>
    </AuthLayout>
  );
}

export default ChangePassword;
