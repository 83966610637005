import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export const dataRetentionSliceSelector = (state: RootState) => state.dataRetention;

export const canCreateDataRetentionPolicySelector = createSelector(
  dataRetentionSliceSelector,
  (state) => state.canCreatePolicy,
);

export const isLoadingDataRetentionProductsSelector = createSelector(
  dataRetentionSliceSelector,
  (state) => state.isLoading,
);
