import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

export default function PermissionDenied() {
  return (
    <div className="pt-6">
      <div className="container px-2">
        <div className="content card px-5 py-5">
          <h2 className="is-size-2">Permission Denied</h2>
          <p>
            You may visit <Link to={RoutePath.root}>the Main Page</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
