import getEnvVariable from './getEnvVariable';
import { Converter } from '@itm/shared-frontend/lib/utils';

import {
  TargetPlatform,
  ClientPortalRole,
  UserStatus,
  PhoneNumberType,
  SelectOption,
  UserSQLPermissions,
  PaymentMethod,
  PensionType,
  BulkUploadAction,
  BulkUploadArea,
  SourceFileStatus,
  DataAccessDurationType,
  UserDataPermissionStatus,
  DataAccessRequestStatusDto,
  DataRetentionPolicyStatus,
  DataRetentionPolicyType,
  DataRetentionProductRuleType,
  DataRetentionPolicyApprovalStatus,
  DataRetentionAuditStatus,
  CompanyDefaultSchemeAccessAction,
  AdditionalSchemeType,
  PensionStatus,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');
export const DATA_GATEWAY_API_URL = getEnvVariable('DATA_GATEWAY_API_URL');

export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const getRoleWithoutPrefix = (role: string) => role.slice(role.indexOf('_') + 1);
const roleSortPriority = [
  getRoleWithoutPrefix(ClientPortalRole.SuperAdmin),
  getRoleWithoutPrefix(ClientPortalRole.Support),
  getRoleWithoutPrefix(ClientPortalRole.Analyst),
  getRoleWithoutPrefix(ClientPortalRole.Viewer),
  getRoleWithoutPrefix(ClientPortalRole.ClientAdmin),
  getRoleWithoutPrefix(ClientPortalRole.Manager),
] as const;

export const roleSortPrioritySortFn = (a: string, b: string) =>
  roleSortPriority.indexOf(getRoleWithoutPrefix(a)) - roleSortPriority.indexOf(getRoleWithoutPrefix(b));
export const userRoleOptions: SelectOption[] = Object.values(ClientPortalRole)
  .sort(roleSortPrioritySortFn)
  .map((value) => ({ value, label: Converter.stripUpperToCapitalizedAll(getRoleWithoutPrefix(value)) }));
export const userStatusOptions: SelectOption[] = Converter.enumToSelectOptions(UserStatus);
export const phoneNumberTypeOptions: SelectOption[] = [
  { label: PhoneNumberType.Main, value: PhoneNumberType.Main },
  { label: Converter.stripUpperToCapitalizedFirst(PhoneNumberType.NonUK), value: PhoneNumberType.NonUK },
  { label: PhoneNumberType.SMS, value: PhoneNumberType.SMS },
  { label: PhoneNumberType.Welsh, value: PhoneNumberType.Welsh },
  { label: PhoneNumberType.WhatsApp, value: PhoneNumberType.WhatsApp },
];
export const pensionTypeOptions: SelectOption[] = Converter.enumToSelectOptions(PensionType);
export const userSQLPermissionOptions: SelectOption[] = Converter.enumToSelectOptions(UserSQLPermissions);
export const paymentMethodOptions: SelectOption[] = Converter.enumToSelectOptions(PaymentMethod);
export const bulkUploadActionOptions: SelectOption[] = Converter.enumToSelectOptions(BulkUploadAction);
export const bulkUploadAreaOptions: SelectOption[] = Converter.enumToSelectOptions(BulkUploadArea);
export const sourceFileStatusOptions: SelectOption[] = Converter.enumToSelectOptions(SourceFileStatus);
export const dataAccessDurationTypeOptions: SelectOption[] = Converter.enumToSelectOptions(DataAccessDurationType);
export const userDataPermissionStatusOptions: SelectOption[] = Converter.enumToSelectOptions(UserDataPermissionStatus);
export const dataAccessRequestStatusOptions: SelectOption[] = Converter.enumToSelectOptions(DataAccessRequestStatusDto);
export const dataRetentionPolicyStatusOptions: SelectOption[] =
  Converter.enumToSelectOptions(DataRetentionPolicyStatus);
export const dataRetentionPolicyTypeOptions: SelectOption[] = Converter.enumToSelectOptions(DataRetentionPolicyType);
export const dataRetentionProductRuleTypeOptions: SelectOption[] =
  Converter.enumToSelectOptions(DataRetentionProductRuleType);
export const dataRetentionPolicyApprovalStatusOptions: SelectOption[] = Converter.enumToSelectOptions(
  DataRetentionPolicyApprovalStatus,
);
export const dataRetentionAuditStatusOptions: SelectOption[] = Converter.enumToSelectOptions(DataRetentionAuditStatus);
export const companyDefaultSchemeAccessActionOptions: SelectOption[] = Converter.enumToSelectOptions(
  CompanyDefaultSchemeAccessAction,
);
export const additionalSchemeTypeOptions: SelectOption[] = [
  { value: AdditionalSchemeType.FOR, label: 'Former' },
  { value: AdditionalSchemeType.OTH, label: 'Other' },
];
export const pensionStatusOptions: SelectOption[] = [
  { value: PensionStatus.IWU, label: 'Inactive and winding up' },
  { value: PensionStatus.IPPF, label: 'Inactive and PPF assessment' },
];

export const apiAddressRegExp =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$|^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

/**
 * Synchronized with BE
 */
export const strongPasswordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&£^()_+{}:~<>|=[;'#,./\-\]\\])[A-Za-z\d$@!%*?&£^()_+{}:~<>|=[;'#,./\-\]\\]{4,}$/;
