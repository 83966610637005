import axios, { AxiosRequestConfig } from 'axios';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { PageLoading } from '@itm/shared-frontend/lib/components';
import { codeValidation, ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessage } from '@itm/shared-frontend/lib/components/forms';

import { getAuthenticatorDetails, verify2FAToken } from 'src/api/auth/twoFactorAuthentication';

import AuthLayout from 'src/components/layout/AuthLayout';

import { AuthenticatorDetailsResponse, LoginUserModel, TwoFactorLoginUserModel, ServerError } from 'src/types';

type Props = {
  credentials: LoginUserModel | undefined;
  onboardingRequestConfig: AxiosRequestConfig<any>;
  loginStageRequest: (payload: TwoFactorLoginUserModel) => Promise<void>;
};
type FormData = Pick<TwoFactorLoginUserModel, 'code'>;

const formSchema: ObjectSchema<FormData> = object({
  code: codeValidation,
});

function SetupMFA({ credentials, loginStageRequest, onboardingRequestConfig }: Props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticator, setAuthenticator] = useState<AuthenticatorDetailsResponse>();
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [globalServerErrorMessage, setGlobalServerErrorMessage] = useState('');
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const { register, control, handleSubmit, formState } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const requestAuthenticator = useCallback(async () => {
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setIsLoading(true);
    try {
      const config = { signal: abortController.signal, ...onboardingRequestConfig };
      const res = await getAuthenticatorDetails(config);

      setAuthenticator(res.data);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setGlobalServerErrorMessage(formErrors[0]);
    } finally {
      setIsLoading(false);
      abortControllerSet.delete(abortController);
    }
  }, [abortControllerSet, onboardingRequestConfig]);

  const onSubmit = handleSubmit(async ({ code }) => {
    setServerErrorMessage('');
    try {
      await verify2FAToken(code, onboardingRequestConfig);

      if (credentials) {
        await loginStageRequest({ ...credentials, code });
      } else {
        navigate(RoutePath.root, { replace: true });
      }
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessage(formErrors[0]);
    }
  });

  useEffect(() => {
    requestAuthenticator();
  }, [requestAuthenticator]);

  return (
    <AuthLayout>
      <div className="has-text-centered">
        <h2 className="is-size-2 mb-4">MFA Setup</h2>
        {isLoading ? (
          <PageLoading />
        ) : (
          <>
            <ServerErrorMessage message={globalServerErrorMessage} />
            {authenticator && (
              <>
                <p className="is-size-5 has-text-weight-semibold px-4 mb-4">
                  Please scan this QR code in Authenticator App
                </p>
                <div className="mb-4">
                  <QRCodeSVG value={authenticator.authenticatorUri || ''} size={150} />
                </div>
                <p className="is-size-5 has-text-weight-semibold mb-4">or you can enter this code manually:</p>
                <div className="is-size-5 mb-4">
                  <code>{authenticator.sharedKey}</code>
                </div>
                <p className="is-size-5 has-text-weight-semibold mb-5">and enter 6-digit code below</p>
                <form name="mfaSetupForm" onSubmit={onSubmit} noValidate>
                  <Field
                    label=""
                    field="input"
                    type="text"
                    inputMode="numeric"
                    placeholder="Enter code"
                    autoComplete="off"
                    register={register('code')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    autoFocus
                  />

                  <div className="field">
                    <LoadingButton
                      className="button is-interact is-fullwidth"
                      type="submit"
                      isLoading={formState.isSubmitting}
                    >
                      Add MFA
                    </LoadingButton>
                    <ServerErrorMessage message={serverErrorMessage} />
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </div>
    </AuthLayout>
  );
}

export default SetupMFA;
