import { clientPortalApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import {
  UserPermissionResponse,
  AddProductPermissionModel,
  AddBulkPermissionModel,
  DeletePermissionModel,
  DowngradePermissionModel,
  BasePermissionModel,
  PermissionModel,
  BulkAssignSchemeProductPermissions,
  BulkAssignUserProductModel,
  AddClientPortalModel,
  UserEmployerPermissionResponse,
} from 'src/types';

export const getPermissionsAssignedToUser = (userId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<UserPermissionResponse[]>(`/api/v1/permissions/${userId}`, config);
export const getEmployerPermissionsAssignedToUser = (
  userId: string,
  schemeId: string,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<UserEmployerPermissionResponse[]>(
    `/api/v1/permissions/useremployer/${userId}/${schemeId}`,
    config,
  );
export const assignPermissionToUser = (data: AddProductPermissionModel) =>
  clientPortalApi.post<void>('/api/v1/permissions', data);
export const assignBulkPermissionToUser = (data: AddBulkPermissionModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/bulk', data);
export const removePermissionFromUser = (data: DeletePermissionModel) =>
  clientPortalApi.delete<void>('/api/v1/permissions', { data });
export const downgradeUserProductPermission = (data: DowngradePermissionModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/downgrade', data);

export const assignSchemeToUserProduct = (data: PermissionModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/userProduct', data);
export const removeSchemeToUserProduct = (data: PermissionModel) =>
  clientPortalApi.delete<void>('/api/v1/permissions/userProduct', { data });

export const bulkAssignSchemeToUserProduct = (data: BulkAssignSchemeProductPermissions) =>
  clientPortalApi.post<void>('/api/v1/permissions/bulkAssignSchemeToUserProduct', data);
export const bulkUnassignSchemeFromUserProduct = (data: BulkAssignSchemeProductPermissions) =>
  clientPortalApi.post<void>('/api/v1/permissions/bulkUnassignSchemeFromUserProduct', data);

export const assignAllCompaniesToUser = (userId: string) =>
  clientPortalApi.post<void>(`/api/v1/permissions/assign-all-companies/users/${userId}`);
export const unassignAllCompaniesFromUser = (userId: string) =>
  clientPortalApi.post<void>(`/api/v1/permissions/unassign-all-companies/users/${userId}`);

export const assignUserToProduct = (data: BasePermissionModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/assignUserToProduct', data);
export const unassignUserFromProduct = (data: BasePermissionModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/unassignUserFromProduct', data);

export const assignUserToAllProduct = (data: BulkAssignUserProductModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/bulkAssignUserToProduct', data);
export const unassignUserFromAllProduct = (data: BulkAssignUserProductModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/bulkUnassignUserFromProduct', data);

export const updateAllUserRoles = (data: AddClientPortalModel) =>
  clientPortalApi.post<void>('/api/v1/permissions/updateAllUserRoles', data);
