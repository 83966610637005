import { Fragment } from 'react';
import { TechProblemsIcon } from 'src/components/icons';

type Props = {
  title?: string;
  subtitle?: string | string[];
  description?: string | string[];
};

const arrayOfStringToStringWithBreaksAdapter = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.map((string, index) => (
      <Fragment key={`${string}/${index}`}>
        {string}
        <br />
      </Fragment>
    ));
  } else {
    return value;
  }
};

/**
 *
 * @param subtitle may be string[] if you need string with custom breaks
 * @param description may be string[] if you need string with custom breaks
 *
 */

function TechnicalProblems({ title = 'Oops!', subtitle, description }: Props) {
  return (
    <section className="has-text-centered pt-6">
      <div className="has-text-centered mb-5">
        <TechProblemsIcon />
      </div>
      <h2 className="title is-1 has-text-grey mb-5">{title}</h2>
      {subtitle && <p className="is-size-2 has-text-grey mb-5">{arrayOfStringToStringWithBreaksAdapter(subtitle)}</p>}
      {description && (
        <p className="is-size-2 has-text-grey mb-5">{arrayOfStringToStringWithBreaksAdapter(description)}</p>
      )}
    </section>
  );
}

export default TechnicalProblems;
