import { AxiosRequestConfig } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction, miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import { getCompanyListByUserId, getCompanyListAll, getCompanyById } from 'src/api/clientPortal/company';

import { CompanyResponse, CompanyDetailsResponse, ServerError } from 'src/types';

type ViewEditCompany = {
  company: CompanyDetailsResponse | null;
  serverErrors: string[];
  isView: boolean | null;
};

type CompanyState = {
  isLoading: boolean;
  assignedCompanies: CompanyResponse[];
  allCompanies: CompanyResponse[];
  selectedCompanyId: string;
  viewEditCompany: ViewEditCompany;
};

const serializeError = (e: unknown): SerializedError => {
  const message = new ServerErrorAdapter(e as ServerError).combine().join('\n  ');
  return miniSerializeError({ message });
};

export const fetchCompaniesByUserIdAction = createAsyncThunk(
  'company/fetchCompanyListByUserId',
  async (userId: string) => {
    const res = await getCompanyListByUserId(userId);
    return res.data;
  },
);

export const fetchCompaniesAllAction = createAsyncThunk('company/fetchCompanyListAll', async () => {
  const res = await getCompanyListAll();
  return res.data;
});

type FetchViewEditCompanyByIdActionParams = {
  companyId: string;
  config: AxiosRequestConfig<any>;
};
export const fetchViewEditCompanyByIdAction = createAsyncThunk(
  'company/fetchViewEditCompanyById',
  async ({ companyId, config }: FetchViewEditCompanyByIdActionParams) => {
    const res = await getCompanyById(companyId, config);
    res.data.adminContact?.administratorPhones?.sort((a, b) => a.order - b.order);
    return res.data;
  },
  { serializeError },
);

const initialState: CompanyState = {
  isLoading: false,
  assignedCompanies: [],
  allCompanies: [],
  selectedCompanyId: '',
  viewEditCompany: {
    company: null,
    serverErrors: [],
    isView: null,
  },
};

const userCompaniesSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    selectCompany(state: CompanyState, action: PayloadAction<string>) {
      state.selectedCompanyId = action.payload;
    },
    setAssignedCompanies(state: CompanyState, action: PayloadAction<CompanyResponse[]>) {
      state.assignedCompanies = action.payload;
    },
    setIsViewCompany(state: CompanyState, action: PayloadAction<boolean | null>) {
      state.viewEditCompany.isView = action.payload;
    },
    resetViewEditCompany(state: CompanyState) {
      state.viewEditCompany = initialState.viewEditCompany;
    },
  },
  extraReducers: (builder) => {
    // SuperAdmin and Client Admin
    builder.addCase(fetchCompaniesByUserIdAction.pending, (state: CompanyState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompaniesByUserIdAction.fulfilled, (state: CompanyState, action) => {
      state.isLoading = false;
      state.assignedCompanies = action.payload;
    });
    builder.addCase(fetchCompaniesByUserIdAction.rejected, (state: CompanyState) => {
      state.isLoading = false;
    });

    // SuperAdmin
    builder.addCase(fetchCompaniesAllAction.pending, (state: CompanyState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompaniesAllAction.fulfilled, (state: CompanyState, action) => {
      state.isLoading = false;
      state.allCompanies = action.payload;
    });
    builder.addCase(fetchCompaniesAllAction.rejected, (state: CompanyState) => {
      state.isLoading = false;
    });

    // View/Edit Company
    builder.addCase(fetchViewEditCompanyByIdAction.pending, (state: CompanyState) => {
      state.viewEditCompany.serverErrors = [];
    });
    builder.addCase(fetchViewEditCompanyByIdAction.fulfilled, (state: CompanyState, action) => {
      state.viewEditCompany.company = action.payload;
    });
    builder.addCase(fetchViewEditCompanyByIdAction.rejected, (state: CompanyState, action) => {
      state.viewEditCompany.serverErrors = action.error.message ? [action.error.message] : [];
    });

    // Cleanup
    builder.addCase(resetStore, (state: CompanyState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { selectCompany, setAssignedCompanies, setIsViewCompany, resetViewEditCompany } =
  userCompaniesSlice.actions;

export default userCompaniesSlice.reducer;
