import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import {
  tenantBrandingNameSelector,
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
} from 'src/store/selectors/tenantBrandingSelector';

import styles from './AuthLayout.module.scss';

function AuthLayout({ children }: React.PropsWithChildren<object>) {
  const brandingName = useSelector(tenantBrandingNameSelector);
  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);

  return (
    <div className={styles.Wrapper}>
      <header className={classNames(styles.Header, 'has-background-grey-lightest')}>
        <nav className="navbar has-background-transparent" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to={RoutePath.root}>
                <img className="site-logo" src={brandingLogoUrl} alt={brandingLogoAlt} />
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <main className={classNames(styles.Main, 'is-flex is-flex-direction-column has-background-white')}>
        {children}
        <footer className={classNames(styles.Footer, 'has-text-centered is-size-7 pt-6')}>
          By clicking on the Login button you are agreeing to
          <br />
          {brandingName} <Link to={RoutePath.termsOfUse}>Terms of Use</Link>
        </footer>
      </main>
    </div>
  );
}

export default AuthLayout;
