import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from 'react-router-dom';

type RouterState = {
  previousLocation: Location;
};

const initialState: RouterState = {
  previousLocation: { pathname: '/', hash: '', search: '', state: null, key: '' },
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setPreviousLocation(state: RouterState, action: PayloadAction<Location>) {
      state.previousLocation = action.payload;
    },
  },
});

export const { setPreviousLocation } = routerSlice.actions;

export default routerSlice.reducer;
