import { clientPortalApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import {
  CompanyResponse,
  CompanyDetailsResponse,
  CompanySearchResponse,
  CreateCompanyModel,
  UpdateCompanyModel,
  UserCompanyAllSchemesAccessResponsePagedResponse,
  CompanyDefaultSchemeAccessAction,
  DefaultSchemeAccessAuditResponsePagedResponse,
} from 'src/types';

export const getCompanyById = (companyId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<CompanyDetailsResponse>(`/api/v1/companies/${companyId}`, config);
export const getCompanyListSearch = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<CompanySearchResponse>('/api/v1/companies/search', config);
export const getCompanyListAll = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<CompanyResponse[]>('/api/v1/companies/all', config);
export const getCompanyListByUserId = (userId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<CompanyResponse[]>(`/api/v1/companies/assignedToUser/${userId}`, config);
export const getCompanyListByProductId = (productId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<CompanyResponse[]>(`/api/v1/companies/assignedToProduct/${productId}`, config);

export const createCompany = (data: CreateCompanyModel) => clientPortalApi.post<string>('/api/v1/companies', data);
export const updateCompany = (data: UpdateCompanyModel) => clientPortalApi.patch<void>('/api/v1/companies', data);
export const updateCompanyLogo = (companyId: string, formData: FormData, config: AxiosRequestConfig = {}) =>
  clientPortalApi.post<void>(`/api/v1/companies/${companyId}/logo`, formData, config);

export const deleteCompanyById = (companyId: string) =>
  clientPortalApi.delete<void>('/api/v1/companies/', { params: { companyId } });

export type CompanyUserListByDefaultSchemeAccessSearchParams = TanstackTableSearchParamsAdapter & {
  hasDefaultSchemesAccess: boolean;
};

export const getCompanyUserListByDefaultSchemeAccessSearch = (
  companyId: string,
  params: CompanyUserListByDefaultSchemeAccessSearchParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<UserCompanyAllSchemesAccessResponsePagedResponse>(
    `/api/v1/companies/${companyId}/default-scheme-access`,
    { ...config, params },
  );
export const updateCompanyDefaultSchemeAccess = (companyId: string, hasDefaultSchemesAccess: boolean) =>
  clientPortalApi.patch<void>(`/api/v1/companies/${companyId}/default-scheme-access/${hasDefaultSchemesAccess}`);
export const updateCompanyUserDefaultSchemeAccess = (
  companyId: string,
  userId: string,
  hasDefaultSchemesAccess: boolean,
) =>
  clientPortalApi.patch<void>(
    `/api/v1/companies/${companyId}/user/${userId}/default-scheme-access/${hasDefaultSchemesAccess}`,
  );

export type CompanyDefaultSchemeAccessAuditSearchParams = TanstackTableSearchParamsAdapter & {
  action?: CompanyDefaultSchemeAccessAction;
};

export const getCompanyDefaultSchemeAccessAuditSearch = (
  companyId: string,
  params: CompanyDefaultSchemeAccessAuditSearchParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<DefaultSchemeAccessAuditResponsePagedResponse>(
    `/api/v1/companies/${companyId}/default-scheme-access-audit/search`,
    { ...config, params },
  );
