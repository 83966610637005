import { ChallengeType } from 'src/types/auth';
import { history, RoutePath } from 'src/router';

export function handleRedirect(challengeType: ChallengeType): void {
  switch (challengeType) {
    case ChallengeType.SOFTWARE_TOKEN_MFA:
      if (history.location.pathname !== RoutePath.login) {
        history.replace(RoutePath.login);
      }
      break;
    case ChallengeType.MFA_SETUP:
      history.replace(RoutePath.setupMFA);
      break;
    default:
      history.replace(RoutePath.root);
  }
}
