import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, ref, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessage } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import AuthLayout from 'src/components/layout/AuthLayout';

import { sendPasswordResetTokenToEmail, updatePasswordByResetTokenFromEmail } from 'src/api/auth/user';

import { ConfirmEmailModel, UserResetTokenModel, ServerError } from 'src/types';

type FormData = ConfirmEmailModel & {
  passwordConfirmation: string;
};

function ResetPassword() {
  const navigate = useNavigate();
  const [isCodeStage, setIsCodeStage] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const formSchema: ObjectSchema<FormData> = useMemo(
    () =>
      object({
        email: string().label('Email').trim().lowercase().required().email(),
        password: isCodeStage ? string().password() : string().label('Password').trim().ensure(),
        token: string()
          .label('Code')
          .trim()
          .ensure()
          .when((_, schema) => (isCodeStage ? schema.required() : schema)),
        passwordConfirmation: string()
          .label('Password')
          .trim()
          .ensure()
          .when((_, schema) =>
            isCodeStage ? schema.required().oneOf([ref('password')], 'Passwords should be the same') : schema,
          ),
      }),
    [isCodeStage],
  );

  const { register, control, handleSubmit, formState } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = handleSubmit(async ({ email, token, password }) => {
    try {
      if (isCodeStage) {
        const payload: ConfirmEmailModel = { email, token, password };
        await updatePasswordByResetTokenFromEmail(payload);
        navigate(RoutePath.resetPasswordCompleted);
      } else {
        const payload: UserResetTokenModel = { email };
        await sendPasswordResetTokenToEmail(payload);
        setIsCodeStage(true);
      }
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessage(formErrors[0]);
    }
  });

  return (
    <AuthLayout>
      <h1 className="is-size-2 has-text-centered mb-5">Reset Password</h1>
      <p className="is-size-3 has-text-centered mb-5">
        {isCodeStage
          ? 'A password reset notification has been sent to the registered email address.  If you do not receive an email, please check your junk mail and then re-enter you username and try again. If you still do not receive a password reset email, please contact your scheme administrator.'
          : 'Enter your email. You will be sent an email containing password reset instructions'}
      </p>
      <form name="resetPasswordForm" onSubmit={onSubmit} noValidate>
        <Field
          label="Email"
          field="input"
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          readOnly={isCodeStage}
          register={register('email')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          autoFocus
        />

        {isCodeStage ? (
          <>
            <Field
              className="pt-4"
              label="Code from email"
              field="input"
              type="text"
              inputMode="numeric"
              placeholder="Enter code from email"
              autoComplete="off"
              autoFocus
              register={register('token')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />

            <Field
              label="New Password"
              field="input"
              type="password"
              placeholder="Enter your new password"
              autoComplete="new-password"
              register={register('password')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />

            <Field
              label="Confirm New Password"
              field="input"
              type="password"
              placeholder="Confirm your new password"
              autoComplete="off"
              register={register('passwordConfirmation')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
          </>
        ) : null}

        <div className="field pt-4">
          <LoadingButton className="button is-interact is-fullwidth" type="submit" isLoading={formState.isSubmitting}>
            {isCodeStage ? 'Set New password' : 'Reset password'}
          </LoadingButton>
          <ServerErrorMessage message={serverErrorMessage} />
        </div>
      </form>
    </AuthLayout>
  );
}

export default ResetPassword;
