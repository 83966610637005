import classNames from 'classnames';
import { useState, useEffect, PropsWithChildren } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

import styles from './Layout.module.scss';

const LAPTOP_BREAKPOINT = 1366;

function Layout({ children }: PropsWithChildren<object>) {
  const [isActiveSidebar, setIsActiveSidebar] = useState(false);

  const toggleActiveSidebar = () => {
    if (window.innerWidth < LAPTOP_BREAKPOINT) {
      setIsActiveSidebar(false);
    } else {
      setIsActiveSidebar(true);
    }
  };

  useEffect(() => {
    toggleActiveSidebar();
    window.addEventListener('resize', toggleActiveSidebar);
    return () => {
      window.removeEventListener('resize', toggleActiveSidebar);
    };
  }, []);

  return (
    <div className={classNames(styles.Wrapper, 'is-flex is-flex-direction-row-reverse')}>
      <div
        className={classNames(
          `${isActiveSidebar ? styles.MainWithActiveSidebar : styles.MainWithCollapsedSidebar}`,
          'is-flex is-flex-direction-column',
        )}
      >
        <Header />
        <main
          className={classNames(
            styles.Main,
            'is-flex is-flex-direction-column is-flex-grow-1 p-5-tablet-only pt-4-mobile px-3-mobile',
          )}
        >
          {children}
        </main>
      </div>
      <Sidebar isActive={isActiveSidebar} setIsActive={setIsActiveSidebar} />
    </div>
  );
}

export default Layout;
