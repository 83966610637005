import classNames from 'classnames';
import { useMemo, useContext } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { AuthContext } from 'src/context/Auth';

import { useSelector } from 'src/store';
import {
  tenantBrandingNameSelector,
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
} from 'src/store/selectors/tenantBrandingSelector';
import { userPermissionsSelector } from 'src/store/selectors/userSelector';
import {
  selectedCompanyIdSelector,
  viewEditCompanyNameSelector,
  isViewCompanySelector,
} from 'src/store/selectors/companySelector';
import { canCreateDataRetentionPolicySelector } from 'src/store/selectors/dataRetentionSelector';

import { Navigation } from '@itm/shared-frontend/lib/components';
import { MenuIcon, HideIcon } from '@itm/shared-frontend/lib/components/icons';
import type { NavigationLocalConfig } from '@itm/shared-frontend/lib/components/Navigation';

import { isAzureTargetPlatform } from 'src/utils/constants';

import CompanyFilter from 'src/components/CompanyFilter';

import { ClientPortalRole, DataGatewayRole, GlobalRole } from 'src/types';

import styles from './Sidebar.module.scss';

type Props = {
  isActive: boolean;
  setIsActive: any;
};

function Sidebar({ isActive, setIsActive }: Props) {
  const { isLoggedIn, clientPortalUserRole, dataGatewayUserRole } = useContext(AuthContext);

  const companyId = useSelector(selectedCompanyIdSelector);
  const viewEditCompanyName = useSelector(viewEditCompanyNameSelector);
  const isViewCompany = useSelector(isViewCompanySelector);

  const userPermissionList = useSelector(userPermissionsSelector);

  const brandingName = useSelector(tenantBrandingNameSelector);
  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);

  const canCreateDataRetentionPolicy = useSelector(canCreateDataRetentionPolicySelector);

  const userGlobalRoleListByCompany = useMemo(
    () =>
      companyId && userPermissionList.length
        ? userPermissionList.reduce<GlobalRole[]>((acc, permission) => {
            if (companyId === permission.companyId && !acc.includes(permission.productRoleName)) {
              acc.push(permission.productRoleName);
            }
            return acc;
          }, [])
        : [],
    [companyId, userPermissionList],
  );

  const userRoleList = useMemo(() => {
    if (!clientPortalUserRole) return [];
    if (clientPortalUserRole === ClientPortalRole.SuperAdmin || clientPortalUserRole === ClientPortalRole.Support)
      return [clientPortalUserRole] as unknown as GlobalRole[];

    const roleList = dataGatewayUserRole
      ? [clientPortalUserRole, dataGatewayUserRole, ...userGlobalRoleListByCompany]
      : [clientPortalUserRole, ...userGlobalRoleListByCompany];

    return roleList as unknown as GlobalRole[];
  }, [clientPortalUserRole, dataGatewayUserRole, userGlobalRoleListByCompany]);

  const navigationLocalConfig = useMemo<NavigationLocalConfig<ClientPortalRole | DataGatewayRole>>(
    () => [
      {
        section: 'common',
        name: 'Data Access Permission',
        path: RoutePath.dataAccessRoot,
        isInternalRoute: true,
        role: {
          allowList: isAzureTargetPlatform
            ? []
            : [
                ClientPortalRole.SuperAdmin,
                ClientPortalRole.Support,
                ClientPortalRole.Analyst,
                ClientPortalRole.ClientAdmin,
              ],
        },
        children: [
          {
            name: 'My Access',
            path: RoutePath.dataAccessMyAccessRoot,
            isInternalRoute: true,
            role: {
              allowList: [ClientPortalRole.SuperAdmin, ClientPortalRole.Support, ClientPortalRole.Analyst],
            },
          },
          {
            name: 'Manage Access',
            path: RoutePath.dataAccessManageRoot,
            isInternalRoute: true,
            role: {
              allowList: [ClientPortalRole.ClientAdmin],
            },
          },
        ],
      },
      {
        section: 'common',
        name: 'Data Retention',
        path: RoutePath.dataRetentionRoot,
        isInternalRoute: true,
        role: {
          allowList: [
            ClientPortalRole.SuperAdmin,
            ClientPortalRole.Support,
            ClientPortalRole.Analyst,
            ClientPortalRole.ClientAdmin,
          ],
        },
        children: [
          {
            name: 'All Policies',
            path: generatePath(RoutePath.dataRetentionPolicyList, { companyId }),
            isInternalRoute: true,
          },
          {
            name: canCreateDataRetentionPolicy ? 'Create new policy' : null,
            path: generatePath(RoutePath.dataRetentionPolicyCreateRoot, { companyId }),
            isInternalRoute: true,
          },
          {
            name: 'Data Retention Logs',
            path: generatePath(RoutePath.dataRetentionLogs, { companyId }),
            isInternalRoute: true,
          },
        ],
      },
      {
        section: 'common',
        name: 'Data Publish Configuration',
        path: RoutePath.dataPublishRoot,
        isInternalRoute: true,
        role: {
          allowList: [ClientPortalRole.SuperAdmin, ClientPortalRole.Support],
        },
        children: [
          {
            name: 'Configuration',
            path: RoutePath.dataPublishConfiguration,
            isInternalRoute: true,
          },
        ],
      },
      {
        section: 'common',
        name: 'Users',
        path: RoutePath.administrationUserRoot,
        isInternalRoute: true,
        role: {
          allowList: [
            ClientPortalRole.SuperAdmin,
            ClientPortalRole.Support,
            ClientPortalRole.Analyst,
            ClientPortalRole.ClientAdmin,
          ],
        },
        children: [
          {
            name: 'Manage Users',
            path: RoutePath.administrationUserList,
            isInternalRoute: true,
          },
          {
            name: 'Invite New User',
            path: RoutePath.administrationUserCreateRoot,
            isInternalRoute: true,
          },
        ],
      },
      {
        section: 'common',
        name: 'API Integrations',
        path: RoutePath.apiIntegrationsRoot,
        isInternalRoute: true,
        role: {
          allowList: isAzureTargetPlatform
            ? []
            : [
                DataGatewayRole.SuperAdmin,
                DataGatewayRole.Support,
                DataGatewayRole.Analyst,
                DataGatewayRole.ClientAdmin,
              ],
        },
        children: [
          {
            name: 'Manage Integrations',
            path: RoutePath.apiIntegrationsList,
            isInternalRoute: true,
          },
          {
            name: 'New API Integration to DataHub',
            path: RoutePath.apiIntegrationsCreateRoot,
            isInternalRoute: true,
          },
        ],
      },
      {
        section: 'common',
        name: 'Products Configuration',
        path: RoutePath.administrationProducts,
        isInternalRoute: true,
        role: {
          allowList: [ClientPortalRole.SuperAdmin, ClientPortalRole.Support],
        },
        children: [
          {
            name: 'Manage Products',
            path: RoutePath.administrationProducts,
            isInternalRoute: true,
          },
          {
            name: 'New Product',
            path: RoutePath.productCreate,
            isInternalRoute: true,
          },
        ],
      },
      {
        section: 'common',
        name: 'Onboarding',
        path: RoutePath.administrationOnboardingRoot,
        isInternalRoute: true,
        role: {
          allowList: [
            ClientPortalRole.SuperAdmin,
            ClientPortalRole.Support,
            ClientPortalRole.Analyst,
            ClientPortalRole.ClientAdmin,
          ],
        },
        children: [
          {
            name: 'Manage Companies',
            path: RoutePath.administrationOnboardingCompanyList,
            isInternalRoute: true,
            role: {
              allowList: [
                ClientPortalRole.SuperAdmin,
                ClientPortalRole.Support,
                ClientPortalRole.Analyst,
                ClientPortalRole.ClientAdmin,
              ],
            },
          },
          {
            name: viewEditCompanyName,
            path: isViewCompany
              ? generatePath(RoutePath.administrationOnboardingCompanyViewRoot, { companyId })
              : generatePath(RoutePath.administrationOnboardingCompanyUpdateRoot, { companyId }),
            isInternalRoute: true,
            role: {
              allowList: [
                ClientPortalRole.SuperAdmin,
                ClientPortalRole.Support,
                ClientPortalRole.Analyst,
                ClientPortalRole.ClientAdmin,
              ],
            },
            children: [
              {
                name: 'Company Details',
                path: isViewCompany
                  ? generatePath(RoutePath.administrationOnboardingCompanyViewDetails, {
                      companyId,
                    })
                  : generatePath(RoutePath.administrationOnboardingCompanyUpdateDetails, {
                      companyId,
                    }),
              },
              {
                name: isViewCompany ? null : 'Add new scheme',
                path: generatePath(RoutePath.administrationOnboardingCompanyUpdateCreateScheme, {
                  companyId,
                }),
              },
              {
                name: 'Schemes',
                path: isViewCompany
                  ? generatePath(RoutePath.administrationOnboardingCompanyViewSchemeRoot, {
                      companyId,
                    })
                  : generatePath(RoutePath.administrationOnboardingCompanyUpdateSchemeRoot, {
                      companyId,
                    }),
              },
              {
                name: 'Products',
                path: isViewCompany
                  ? generatePath(RoutePath.administrationOnboardingCompanyViewProducts, {
                      companyId,
                    })
                  : generatePath(RoutePath.administrationOnboardingCompanyUpdateProducts, {
                      companyId,
                    }),
              },
              {
                name: 'Scheme access',
                path: isViewCompany
                  ? generatePath(RoutePath.administrationOnboardingCompanyViewSchemeAccessRoot, {
                      companyId,
                    })
                  : generatePath(RoutePath.administrationOnboardingCompanyUpdateSchemeAccessRoot, {
                      companyId,
                    }),
                role: {
                  allowList: [ClientPortalRole.SuperAdmin, ClientPortalRole.Support, ClientPortalRole.Analyst],
                },
              },
            ],
          },
          {
            name: 'Add New Company',
            path: RoutePath.administrationOnboardingCompanyCreateRoot,
            isInternalRoute: true,
            role: {
              allowList: [ClientPortalRole.SuperAdmin, ClientPortalRole.Support, ClientPortalRole.Analyst],
            },
          },
          {
            name: 'Bulk Onboard',
            path: RoutePath.administrationOnboardingOnboardRoot,
            isInternalRoute: true,
            role: {
              allowList: [
                ClientPortalRole.ClientAdmin,
                ClientPortalRole.SuperAdmin,
                ClientPortalRole.Analyst,
                ClientPortalRole.Support,
              ],
            },
            children: [
              {
                name: 'Upload History',
                path: generatePath(RoutePath.administrationOnboardingBulkUploadHistory, {
                  companyId,
                }),
              },
              {
                name: 'Upload Config',
                path: generatePath(RoutePath.administrationOnboardingBulkUpload, {
                  companyId,
                }),
              },
            ],
          },
          {
            name: 'Tenant Configuration',
            path: RoutePath.administrationOnboardingTenantRoot,
            isInternalRoute: true,
            role: { allowList: [ClientPortalRole.SuperAdmin] },
          },
        ],
      },
      {
        name: 'All Products',
        section: 'products',
        path: RoutePath.dashboard,
        isInternalRoute: true,
        icon: () => <MenuIcon />,
      },
    ],
    [canCreateDataRetentionPolicy, isViewCompany, companyId, viewEditCompanyName],
  );

  return (
    <aside
      className={classNames([
        styles.Wrapper,
        'is-flex is-flex-direction-column has-background-white is-relative',
        {
          [styles.IsActive]: isActive,
        },
      ])}
      id="sidebar"
    >
      <header className={classNames(styles.Header, 'is-flex-shrink-0')}>
        <div className="columns is-align-items-center is-gapless mb-0">
          <div className="column is-narrow">
            <Link className={classNames(styles.Logo, 'has-icon')} to={RoutePath.root}>
              <img src={brandingLogoUrl} alt={brandingLogoAlt} />
            </Link>
          </div>
          {isActive && (
            <div className="column">
              <h2 className="title is-nowrap is-4 ml-4">{brandingName}</h2>
            </div>
          )}
        </div>
        {isActive && <CompanyFilter />}
      </header>
      {isLoggedIn && (
        <>
          <button
            className={classNames(styles.ToggleButton, 'has-icon', { 'is-active': isActive })}
            type="button"
            onClick={() => setIsActive(!isActive)}
            aria-controls="sidebar"
            aria-label={isActive ? 'Collapse sidebar' : 'Expand sidebar'}
          >
            <span className="icon">
              <HideIcon />
            </span>
          </button>
          {clientPortalUserRole && (
            <Navigation
              isActiveSidebar={isActive}
              localConfig={navigationLocalConfig}
              userRoleList={userRoleList}
              isAzureTargetPlatform={isAzureTargetPlatform}
            />
          )}
        </>
      )}
    </aside>
  );
}

export default Sidebar;
