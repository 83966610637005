import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RoutePath, GuardedRoute } from 'src/router';

import { useDispatch } from 'src/store';
import { setIsLoginJourneyFinished } from 'src/store/slices/authSlice';

import LoginPage from './Login';
import LogoutPage from './Logout';
import SignUpPage from './SignUp';
import SetupMFAPage from './SetupMFA';
import ChangeMFAPage from './ChangeMFA';
import ChangePasswordPage from './ChangePassword';
import ChangePasswordCompletedPage from './ChangePasswordCompleted';
import ResetPasswordPage from './ResetPassword';
import ResetPasswordCompletedPage from './ResetPasswordCompleted';
import ResetMFAPage from './ResetMFA';
import TermsOfUsePage from './TermsOfUse';

import { handleRedirect } from './common';
import { updateRequestConfigByAccessToken } from 'src/utils/createAxiosInstance';
import { identity, AuthContext, clientPortalChannel } from 'src/context/Auth';

import { loginUser } from 'src/api/auth/auth';
import { loginUser2FA } from 'src/api/auth/twoFactorAuthentication';

import { LoginUserModel, TwoFactorLoginUserModel, LoginResponse, ChallengeType } from 'src/types';
import { LoginParams } from '@itm/shared-frontend/lib/components/initial';

function AuthRoutes() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useContext(AuthContext);
  const [credentials, setCredentials] = useState<LoginUserModel>();
  const [loginResponseData, setLoginResponseData] = useState<LoginResponse>();

  const challengeType = loginResponseData?.challengeType;
  const onboardingRequestConfig = useMemo(
    () => updateRequestConfigByAccessToken<AxiosRequestConfig>(loginResponseData?.accessToken || '', {}),
    [loginResponseData?.accessToken],
  );

  const loginStageRequest = useCallback(
    async ({ email, password, code }: TwoFactorLoginUserModel) => {
      const res =
        challengeType === ChallengeType.SOFTWARE_TOKEN_MFA
          ? await loginUser2FA({ email, password, code })
          : await loginUser({ email, password });

      setLoginResponseData(res.data);
      setCredentials((prev) => (prev?.password === password ? prev : { email, password }));
    },
    [challengeType],
  );

  const finishLogin = useCallback(() => {
    if (!loginResponseData) return;
    const { accessToken, expiresIn: accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = loginResponseData;
    if (accessToken && refreshToken) {
      const cookieAuthPayload: LoginParams = {
        accessToken,
        accessTokenExpiresIn,
        refreshToken,
        refreshTokenExpiresIn,
      };

      identity.setCookieAuthState(cookieAuthPayload);
      dispatch(setIsLoginJourneyFinished(true));
      clientPortalChannel.postMessage({ isSignIn: true });
    }
  }, [dispatch, loginResponseData]);

  const loginStageRedirect = useCallback(() => {
    switch (challengeType) {
      case ChallengeType.MFA_SETUP:
      case ChallengeType.NEW_PASSWORD_REQUIRED:
      case ChallengeType.SOFTWARE_TOKEN_MFA:
        handleRedirect(challengeType);
        return;
      case undefined:
        return;
      case null:
        finishLogin();
        return;
      default:
        console.warn('Unexpected challengeType ', challengeType);
    }
  }, [challengeType, finishLogin]);

  const resetStage = useCallback(() => {
    setCredentials(undefined);
    setLoginResponseData(undefined);
  }, []);

  useEffect(() => {
    loginStageRedirect();
  }, [loginStageRedirect]);

  return (
    // "/auth/*"
    <Routes>
      <Route
        path="login"
        element={
          <LoginPage
            challengeType={challengeType}
            credentials={credentials}
            resetStage={resetStage}
            loginStageRequest={loginStageRequest}
          />
        }
      />
      <Route path="logout" element={<LogoutPage />} />

      <Route path="signup/:verificationToken" element={<SignUpPage loginStageRequest={loginStageRequest} />} />

      {(isLoggedIn || challengeType === ChallengeType.MFA_SETUP) && (
        <Route
          path="mfa/setup"
          element={
            <SetupMFAPage
              credentials={credentials}
              loginStageRequest={loginStageRequest}
              onboardingRequestConfig={onboardingRequestConfig}
            />
          }
        />
      )}
      <Route
        path="mfa/change"
        element={
          <GuardedRoute meta={{ auth: true }}>
            <ChangeMFAPage />
          </GuardedRoute>
        }
      ></Route>
      <Route path="mfa/reset" element={<ResetMFAPage />} />

      <Route path="password/change" element={<ChangePasswordPage />} />
      <Route path="password/change/completed" element={<ChangePasswordCompletedPage />} />

      <Route path="password/reset" element={<ResetPasswordPage />} />
      <Route path="password/reset/completed" element={<ResetPasswordCompletedPage />} />

      <Route path="agreements/terms-of-use" element={<TermsOfUsePage />} />

      <Route path="*" element={<Navigate to={RoutePath.root} />} />
    </Routes>
  );
}

export default AuthRoutes;
