import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { DataGatewayRole } from 'src/types';

import { jwtRoleKey } from '@itm/shared-frontend/lib/utils';

export const authSliceSelector = (state: RootState) => state.auth;

export const isLoginJourneyFinishedSelector = createSelector(
  authSliceSelector,
  (state) => state.isLoginJourneyFinished,
);

export const authUserDataSelector = createSelector(authSliceSelector, (state) => state.identityUser);

export const authUserAttributesSelector = createSelector(
  authUserDataSelector,
  (userData) =>
    userData?.userAttributes?.reduce<Record<string, string | null>>(
      (acc, { name, value }) => (name ? { ...acc, [name]: value } : acc),
      {},
    ) || {},
);

export const dataGatewayUserRoleSelector = createSelector(
  authUserAttributesSelector,
  (userAttributes): DataGatewayRole | null => {
    const rolesAttribute: string | null = userAttributes[jwtRoleKey] || null;
    if (!rolesAttribute) return null;
    const roles = rolesAttribute.split(/;\s?/).map((roleWithExtra: string) => {
      const index = roleWithExtra.indexOf(':');
      const role = index === -1 ? roleWithExtra : roleWithExtra.slice(0, index);
      return role.toLocaleLowerCase();
    });

    return Object.values(DataGatewayRole).find((role) => roles.includes(role.toLocaleLowerCase())) || null;
  },
);
