import LoginForm from './components/LoginForm';
import AuthLayout from 'src/components/layout/AuthLayout';

import { ChallengeType, LoginUserModel, TwoFactorLoginUserModel } from 'src/types';

type Props = {
  challengeType: ChallengeType | null | undefined;
  credentials: LoginUserModel | undefined;
  resetStage: () => void;
  loginStageRequest: (payload: TwoFactorLoginUserModel) => Promise<void>;
};

function Login(props: Props) {
  return (
    <AuthLayout>
      <h1 className="is-size-2 has-text-centered mb-6">Log in</h1>
      <LoginForm {...props} />
    </AuthLayout>
  );
}

export default Login;
