import { clientPortalApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import {
  ProductResponse,
  ProductSchemeResponse,
  TenantProductResponse,
  CreateProductModel,
  UpdateProductModel,
  AddRoleModel,
  ProductRoleResponse,
  SchemeProductModel,
  CompanyProductModel,
  ClientPortalInfoResponse,
  CompanyIdModel,
} from 'src/types';

export const getProductById = (productId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductResponse>(`/api/v1/products/${productId}`, config);

export const getProductRoleList = (productId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductRoleResponse[]>(`/api/v1/productRoles/byProductId/${productId}`, config);
export const getClientPortalProductRoles = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductRoleResponse[]>('/api/v1/productRoles/clientPortal', config);

export const getProductListAvailableForCompany = (companyId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<TenantProductResponse[]>(`/api/v1/products/availableForCompany/${companyId}`, config);
export const getProductListAssignedToCompany = (companyId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductResponse[]>(`/api/v1/products/assignedToCompany/${companyId}`, config);
export const getProductAndSchemeListAvailableForCompany = (companyId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductSchemeResponse[]>(`/api/v1/products/availableProductSchemes/${companyId}`, config);
export const getProductListByCompanyIdAndSchemeId = (companyId: string, schemeId: string) =>
  clientPortalApi.get<ProductResponse[]>(`/api/v1/products/assignedToCompanyAndScheme/${companyId}/${schemeId}`);
export const getProductListAll = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductResponse[]>('/api/v1/products/all', config);
export const getProductListPromoted = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductResponse[]>('/api/v1/products/promoted', config);
export const getProductListByTenantId = (tenantId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<TenantProductResponse[]>(`/api/v1/products/tenants/${tenantId}`, config);

export const getClientPortalProductInfo = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ClientPortalInfoResponse>('/api/v1/productInfo', config);

export const createProduct = (data: CreateProductModel) => clientPortalApi.post<string>('/api/v1/products', data);
export const updateProduct = (data: UpdateProductModel) => clientPortalApi.patch<void>('/api/v1/products', data);
export const deleteProductById = (productId: string) =>
  clientPortalApi.delete<void>('/api/v1/products', { params: { productId } });
export const updateProductLogo = (productId: string, formData: FormData, config: AxiosRequestConfig = {}) =>
  clientPortalApi.post<void>(`/api/v1/products/${productId}/logo`, formData, config);

export const addRoleToProduct = (data: AddRoleModel) => clientPortalApi.post<string>('/api/v1/products/role', data);
export const assignSchemeToProduct = (data: SchemeProductModel) =>
  clientPortalApi.post<string>('/api/v1/products/assignScheme', data);
export const assignCompanyToProduct = (data: CompanyProductModel) =>
  clientPortalApi.post<string>('/api/v1/products/assignCompany', data);
export const removeSchemeFromProduct = (data: SchemeProductModel) =>
  clientPortalApi.post<string>('/api/v1/products/removeScheme', data);
export const removeCompanyFromProduct = (data: CompanyProductModel) =>
  clientPortalApi.post<string>('/api/v1/products/removeCompany', data);
export const requestDemoForProduct = (productId: string, companyId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.post<string>(`/api/v1/products/${productId}/${companyId}/request-demo`, null, config);

export const getProductListAssignedToUser = (userId: string, config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<ProductResponse[]>(`/api/v1/products/assignedToUser/${userId}`, config);

export const bulkAssignSchemesAndProducts = (data: CompanyIdModel) =>
  clientPortalApi.post<string>('/api/v1/products/bulkAssignSchemesAndProducts', data);
export const bulkUpdateSchemesAndProducts = (data: CompanyIdModel) =>
  clientPortalApi.patch<string>('/api/v1/products/bulkUpdateSchemesAndProducts', data);
