import { useState } from 'react';

import ModuleTile from './ModuleTile';

import { Module } from '..';

type ModuleListProps = {
  moduleList: Module[];
  isRequestDemo?: boolean;
  companyId?: string;
};

function ModuleTileList({ moduleList, isRequestDemo, companyId = '' }: ModuleListProps) {
  const [isRequestDemoLoading, setIsRequestDemoLoading] = useState(false);

  return (
    <div className="columns">
      <div className="column">
        <ul className="columns is-multiline is-align-items-flex-start">
          {moduleList.map((module) => (
            <ModuleTile
              key={module.id}
              {...{
                ...module,
                isRequestDemo,
                companyId,
                isRequestDemoLoading,
                setIsRequestDemoLoading,
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ModuleTileList;
