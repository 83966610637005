import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { clientPortalUserRoleSelector } from 'src/store/selectors/userSelector';
import { ClientPortalRole, SelectOption } from 'src/types';

export const companySliceSelector = (state: RootState) => state.company;

export const allCompanyListSelector = createSelector(companySliceSelector, (state) => state.allCompanies);
export const assignedCompanyListSelector = createSelector(companySliceSelector, (state) => state.assignedCompanies);
export const viewEditCompanySelector = createSelector(companySliceSelector, (state) => state.viewEditCompany);
export const isLoadingCompanySelector = createSelector(companySliceSelector, (state) => state.isLoading);

export const userCompanySelector = createSelector(
  clientPortalUserRoleSelector,
  allCompanyListSelector,
  assignedCompanyListSelector,
  (clientPortalUserRole, allCompanyList, assignedCompanyList) => {
    return clientPortalUserRole === ClientPortalRole.SuperAdmin ||
      clientPortalUserRole === ClientPortalRole.Support ||
      clientPortalUserRole === ClientPortalRole.Viewer
      ? allCompanyList
      : assignedCompanyList;
  },
);

export const viewEditCompanyNameSelector = createSelector(
  viewEditCompanySelector,
  (viewEditCompany): string => viewEditCompany.company?.name ?? '',
);

export const isViewCompanySelector = createSelector(
  viewEditCompanySelector,
  (viewEditCompany) => viewEditCompany.isView,
);

export const userCompanyOptionsSelector = createSelector(userCompanySelector, (userCompanies) => {
  return userCompanies.map<SelectOption>(({ name, id }) => ({ label: name || id, value: id }));
});

export const selectedCompanyIdSelector = createSelector(
  companySliceSelector,
  userCompanyOptionsSelector,
  (state, userCompanyOptions) => {
    if (userCompanyOptions.length) {
      return userCompanyOptions.find((option) => option.value === state.selectedCompanyId)
        ? state.selectedCompanyId
        : userCompanyOptions[0].value;
    }
    return state.selectedCompanyId === 'na' ? '' : state.selectedCompanyId;
  },
);

export const selectedCompanyNameSelector = createSelector(
  selectedCompanyIdSelector,
  userCompanyOptionsSelector,
  (companyId, userCompanyOptions) => {
    if (!companyId) return '';
    const selectedOption = userCompanyOptions.find(({ value }) => value === companyId);
    return selectedOption ? selectedOption.label : '';
  },
);
