import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { getClientPortalProductInfo } from 'src/api/clientPortal/product';
import { ClientPortalInfoResponse } from 'src/types';

type ProductState = {
  productInfo: Partial<ClientPortalInfoResponse>;
};

export const fetchProductInfoAction = createAsyncThunk('product/fetchProductInfo', async () => {
  const res = await getClientPortalProductInfo();
  return res.data;
});

const initialState: ProductState = {
  productInfo: {},
};

const productInfoSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductInfoAction.fulfilled, (state: ProductState, action) => {
      state.productInfo = action.payload;
    });

    // Cleanup
    builder.addCase(resetStore, (state: ProductState) => {
      Object.assign(state, initialState);
    });
  },
});

export default productInfoSlice.reducer;
