import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import AuthLayout from 'src/components/layout/AuthLayout';

type Props = {
  title: string;
};

function SuccessScreen({ title, children }: PropsWithChildren<Props>) {
  return (
    <AuthLayout>
      <h2 className="is-size-2 has-text-centered mb-6">{title}</h2>
      <div className="message is-success">
        <div className="message-body">{children}</div>
      </div>
      <Link className="button is-interact" to={RoutePath.root}>
        Continue &gt;
      </Link>
    </AuthLayout>
  );
}

export default SuccessScreen;
