import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import AuthLayout from 'src/components/layout/AuthLayout';

function ResetMFA() {
  return (
    <AuthLayout>
      <h1 className="is-size-2 has-text-centered mb-6">Reset MFA</h1>
      <p className="is-size-3 has-text-centered">Please raise an ITM support service request ticket through the</p>
      <div className="has-text-centered mb-6">
        <a
          className="is-size-3"
          href="https://support-itmlimited.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer noopener"
        >
          help center
        </a>
      </div>
      <Link className="button is-interact" to={RoutePath.login}>
        Go to login
      </Link>
    </AuthLayout>
  );
}

export default ResetMFA;
