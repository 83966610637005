import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from './storeSlice';

type BreadcrumbsNamesMap = Record<string, string | null>;

type BreadcrumbsState = {
  namesMap: BreadcrumbsNamesMap;
};

const initialState: BreadcrumbsState = {
  namesMap: {},
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbsNames(state: BreadcrumbsState, action: PayloadAction<BreadcrumbsNamesMap>) {
      state.namesMap = { ...state.namesMap, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: BreadcrumbsState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setBreadcrumbsNames } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
