import SuccessScreen from 'src/components/layout/SuccessScreen';

function ChangePasswordCompleted() {
  return (
    <SuccessScreen title="Change Password">
      <p>You successfully changed your password</p>
    </SuccessScreen>
  );
}

export default ChangePasswordCompleted;
