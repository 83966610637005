import { clientPortalApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import {
  DataRetentionPolicyStatus,
  DataRetentionPolicyApprovalStatus,
  DataRetentionPagedResponse,
  DataRetentionAuditPagedResponse,
  DataRetentionVersionPagedResponse,
  DataRetentionDetailsResponse,
  DataRetentionCategoryResponse,
  AvailableDataRetentionProduct,
  CreateDataRetentionDto,
  UpdatePolicyNameDataRetentionDto,
  UpdateDataRetentionDto,
  DataRetentionAuditStatus,
} from 'src/types';

export type DataRetentionPolicyListSearchParams = TanstackTableSearchParamsAdapter & {
  companyId: string;
  schemes?: string;
  status?: DataRetentionPolicyStatus;
};

export const getDataRetentionPolicyListSearch = (
  params: DataRetentionPolicyListSearchParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<DataRetentionPagedResponse>('/api/v1/data-retention/search', {
    ...config,
    params,
  });

export type DataRetentionLogListSearchSearchParams = TanstackTableSearchParamsAdapter & {
  companyId: string;
  schemeIds?: string[];
  productIds?: string[];
  dateFrom?: string;
  dateTo?: string;
  dataCategory?: string;
  status?: DataRetentionAuditStatus;
};

export const getDataRetentionLogListSearch = (
  params: DataRetentionLogListSearchSearchParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<DataRetentionAuditPagedResponse>('/api/v1/data-retention/audits', {
    ...config,
    params,
  });

export type DataRetentionPolicyVersionListSearchParams = TanstackTableSearchParamsAdapter & {
  companyId: string;
  policyId: string;
  status?: DataRetentionPolicyApprovalStatus;
};

export const getDataRetentionPolicyVersionListSearch = (
  params: DataRetentionPolicyVersionListSearchParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<DataRetentionVersionPagedResponse>('/api/v1/data-retention/search/versions', {
    ...config,
    params,
  });

export type DataRetentionPolicyDetailsRequestParams = {
  companyId: string;
  policyId: string;
  policyVersionId: string;
};

export const getDataRetentionPolicyDetails = (
  { companyId, policyId, policyVersionId }: DataRetentionPolicyDetailsRequestParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<DataRetentionDetailsResponse>(
    `/api/v1/data-retention/${policyId}/versions/${policyVersionId}/company/${companyId}`,
    config,
  );

export const getDataRetentionPolicyDetailsView = (
  { companyId, policyId, policyVersionId }: DataRetentionPolicyDetailsRequestParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<DataRetentionDetailsResponse>(
    `/api/v1/data-retention/${policyId}/versions/${policyVersionId}/company/${companyId}/view`,
    config,
  );

export const getDataRetentionCategoryList = (config: AxiosRequestConfig = {}) =>
  clientPortalApi.get<DataRetentionCategoryResponse[]>('/api/v1/data-retention/categories', config);

export type DataRetentionAvailableProductListRequestParams = {
  companyId: string;
  policyId?: string;
};

export const getDataRetentionAvailableProductList = (
  params: DataRetentionAvailableProductListRequestParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<AvailableDataRetentionProduct[]>('/api/v1/data-retention/availableProducts', {
    ...config,
    params,
  });

export type CheckDataRetentionPolicyNameParams = {
  companyId: string;
  name: string;
  policyId?: string;
};

export const checkIsDataRetentionPolicyNameExist = (
  params: CheckDataRetentionPolicyNameParams,
  config: AxiosRequestConfig = {},
) =>
  clientPortalApi.get<boolean>('/api/v1/data-retention/check-if-name-exists', {
    ...config,
    params,
  });

export const createDataRetentionPolicy = (data: CreateDataRetentionDto) =>
  clientPortalApi.post<string>('/api/v1/data-retention', data);

export const updateDataRetentionPolicyName = (data: UpdatePolicyNameDataRetentionDto) =>
  clientPortalApi.patch<string>('/api/v1/data-retention/update-name', data);

export const updateDataRetentionPolicyRules = (data: UpdateDataRetentionDto) =>
  clientPortalApi.patch<string>('/api/v1/data-retention/update-rules', data);
