import { authApi } from '../';
import { AxiosRequestConfig } from 'axios';
import { TwoFactorLoginUserModel, LoginResponse, AuthenticatorDetailsResponse } from 'src/types';

export const loginUser2FA = (data: TwoFactorLoginUserModel) =>
  authApi.post<LoginResponse>('/2FA-auth/login', data, { withCredentials: true });

export const getAuthenticatorDetails = (config: AxiosRequestConfig = {}) =>
  authApi.get<AuthenticatorDetailsResponse>('/2FA-auth/authenticator', config);

export const verify2FAToken = (code: string, config: AxiosRequestConfig = {}) =>
  authApi.post<void>('/2FA-auth/token-verify', { code }, config);
