import { authApi } from '../';
import { AxiosRequestConfig } from 'axios';
import { ChangeCurrentUserPassword, UserResetTokenModel, ConfirmEmailModel } from 'src/types';

export const changeCurrentUserPassword = (data: ChangeCurrentUserPassword, config: AxiosRequestConfig = {}) =>
  authApi.post<void>('/users/current/change-password', data, config);

export const sendPasswordResetTokenToEmail = (data: UserResetTokenModel) =>
  authApi.post<void>('/users/reset-password', data);

export const updatePasswordByResetTokenFromEmail = (data: ConfirmEmailModel) =>
  authApi.post<void>('/users/email-confirm', data);

export const resetCurrentUserMFA = () => authApi.post<void>('/users/current/reset-mfa');
