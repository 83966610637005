import classNames from 'classnames';
import { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { object, string, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { RoutePath } from 'src/router';

import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessage } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter, codeValidation } from '@itm/shared-frontend/lib/utils';

import { LoginUserModel, TwoFactorLoginUserModel, ChallengeType, ServerError } from 'src/types';

import styles from './LoginForm.module.scss';

type Props = {
  challengeType: ChallengeType | null | undefined;
  credentials: LoginUserModel | undefined;
  resetStage: () => void;
  loginStageRequest: (payload: TwoFactorLoginUserModel) => Promise<void>;
};

type FormData = TwoFactorLoginUserModel;

function LoginForm({ challengeType, credentials, resetStage, loginStageRequest }: Props) {
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const isCodeStage = challengeType === ChallengeType.SOFTWARE_TOKEN_MFA;

  const formSchema: ObjectSchema<FormData> = useMemo(
    () =>
      object({
        email: string().label('Email').trim().lowercase().required().email(),
        password: string().label('Password').trim().required(),
        code: isCodeStage ? codeValidation : string().label('Code').trim().ensure(),
      }),
    [isCodeStage],
  );
  const { register, control, handleSubmit, formState, setValue, reset } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const resetCodeStage = () => {
    reset();
    resetStage();
    setServerErrorMessage('');
  };

  const onSubmit = handleSubmit(async (formData) => {
    setServerErrorMessage('');
    try {
      await loginStageRequest(formData);
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessage(formErrors[0]);
    }
  });

  const useAnotherAccountClickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    resetCodeStage();
  };

  useEffect(() => {
    if (!credentials) return;

    setValue('email', credentials.email);
    setValue('password', credentials.password);
  }, [credentials, setValue]);

  return (
    <form name="loginForm" onSubmit={onSubmit} noValidate>
      <Field
        label="Email"
        field="input"
        type="email"
        placeholder="Enter your email"
        autoComplete="email"
        readOnly={isCodeStage}
        register={register('email')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        autoFocus
      />

      <Field
        label="Password"
        field="input"
        type="password"
        placeholder="Enter your password"
        autoComplete="current-password"
        readOnly={isCodeStage}
        register={register('password')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
      />

      {!isCodeStage && (
        <div className={classNames('field', styles.ForgottenYourPasswordHolder)}>
          <Link className="is-size-6" to={RoutePath.resetPassword}>
            Forgotten your password?
          </Link>
        </div>
      )}

      {isCodeStage && (
        <>
          <Field
            label="Verification Code from Authenticator App"
            field="input"
            type="text"
            inputMode="numeric"
            placeholder="Enter code from Authenticator App"
            autoComplete="off"
            autoFocus
            register={register('code')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
          <div className={classNames('field', styles.ForgottenYourPasswordHolder)}>
            <Link className="is-size-6" to={RoutePath.resetMFA}>
              Lost your MFA?
            </Link>
          </div>
        </>
      )}

      <div className="field">
        <LoadingButton className="button is-interact is-fullwidth" type="submit" isLoading={formState.isSubmitting}>
          {isCodeStage ? 'Verify' : 'Log In'}
        </LoadingButton>
        <ServerErrorMessage message={serverErrorMessage} />
      </div>
      {isCodeStage && (
        <div className="has-text-centered">
          <a className="underline" role="button" onClick={useAnotherAccountClickHandler}>
            Use another account
          </a>
        </div>
      )}
    </form>
  );
}

export default LoginForm;
