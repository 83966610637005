import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { logout } from 'src/context/Auth';
import { RoutePath } from 'src/router';

import { Loading } from '@itm/shared-frontend/lib/components';

import Layout from 'src/components/layout/Layout';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await logout();
      window.close();
      navigate(RoutePath.login);
    })();
  }, [navigate]);

  return (
    <Layout>
      <Loading isLoading />
    </Layout>
  );
}

export default Logout;
