import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { CurrentUserResponse, UserPermissionResponse } from 'src/types';

type UserState = {
  clientPortalUser: CurrentUserResponse | null;
  userPermissions: UserPermissionResponse[];
};

const initialState: UserState = {
  clientPortalUser: null,
  userPermissions: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setClientPortalUser(state: UserState, action: PayloadAction<CurrentUserResponse | null>) {
      state.clientPortalUser = action.payload;
    },
    setUserPermissions(state: UserState, action: PayloadAction<UserPermissionResponse[]>) {
      state.userPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: UserState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setClientPortalUser, setUserPermissions } = userSlice.actions;

export default userSlice.reducer;
