import classNames from 'classnames';
import axios from 'axios';
import { useState, useCallback, useMemo, useEffect } from 'react';

import SlideDown from 'react-slidedown';

import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';
import { SuccessModal } from '@itm/shared-frontend/lib/components/modals';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import { requestDemoForProduct } from 'src/api/clientPortal/product';

import { ServerError, ServerFormErrors } from 'src/types';

import LinkWrapper from 'src/components/LinkWrapper';

import styles from './ModuleTile.module.scss';

type Props = {
  id: string;
  title: string;
  to: string | null;
  description: string | null;
  logoUrl?: string;
  IconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isRequestDemo?: boolean;
  companyId?: string;
  isRequestDemoLoading: boolean;
  setIsRequestDemoLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function ModuleTile({
  id,
  title,
  to,
  description,
  logoUrl,
  IconComponent,
  isRequestDemo,
  companyId = '',
  isRequestDemoLoading,
  setIsRequestDemoLoading,
}: Props) {
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const toggleDescription = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsVisibleDescription((isVisible) => !isVisible);
  };

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  const requestDemoClickHandler = useCallback(async () => {
    cleanup();
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setServerErrorMessages((v) => (v.length ? [] : v));
    setIsRequestDemoLoading(true);
    try {
      await requestDemoForProduct(id, companyId, { signal: abortController.signal });
      setIsModalActive(true);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
    setIsRequestDemoLoading(false);
    abortControllerSet.delete(abortController);
  }, [abortControllerSet, cleanup, companyId, id, setIsRequestDemoLoading]);

  useEffect(() => () => cleanup(), [cleanup]);
  return (
    <>
      <li className="products column has-text-centered is-6-tablet is-3-desktop">
        {isRequestDemo ? (
          <div className={classNames(styles.Tile, 'is-request-demo')}>
            <div className="mb-3">
              <div className={styles.LogoHolder}>
                <img src={logoUrl} alt={`${title} logo`} />
              </div>
            </div>
            <strong className="has-text-weight-semibold is-size-5 is-inline-block mb-2">{title}</strong>
            <LoadingButton
              className={classNames(styles.RequestDemoButton, 'button is-interact is-fullwidth')}
              onClick={requestDemoClickHandler}
              disabled={isRequestDemoLoading}
            >
              Request a demo
            </LoadingButton>
            <ServerErrorMessages messages={serverErrorMessages} />
          </div>
        ) : (
          <LinkWrapper className={styles.Tile} to={to}>
            <div className="mb-3">
              {logoUrl ? (
                <div className={styles.LogoHolder}>
                  <img src={logoUrl} alt={`${title} logo`} />
                </div>
              ) : (
                IconComponent && <IconComponent />
              )}
            </div>
            <strong className="has-text-weight-semibold is-size-5 is-inline-block mb-2">{title}</strong>
            <SlideDown>
              {isVisibleDescription && <p className="is-size-6 has-text-black px-4 pb-2">{description}</p>}
            </SlideDown>
            <button
              className={classNames(styles.ToggleButton, 'button is-white is-fullwidth', {
                'is-opened': isVisibleDescription,
              })}
              onClick={(e) => toggleDescription(e)}
              aria-label={isVisibleDescription ? `Collapse ${title} description` : `Show ${title} description`}
              title={isVisibleDescription ? `Collapse ${title} description` : `Show ${title} description`}
            >
              <span className="arrow"></span>
            </button>
          </LinkWrapper>
        )}
        <SuccessModal
          isShowModal={isModalActive}
          closeModal={() => setIsModalActive(false)}
          Body={
            <p className="is-size-3">
              We have sent your request.
              <br />
              We will be in touch with you shortly.
            </p>
          }
          Footer={
            <button className="button is-interact" onClick={() => setIsModalActive(false)}>
              Continue
            </button>
          }
        />
      </li>
    </>
  );
}

export default ModuleTile;
