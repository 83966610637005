import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, ref, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessage } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import AuthLayout from 'src/components/layout/AuthLayout';

import { updatePasswordByResetTokenFromEmail } from 'src/api/auth/user';

import { TwoFactorLoginUserModel, ServerError, ConfirmEmailModel } from 'src/types';

type RouteParams = {
  verificationToken?: string;
};
type Props = {
  loginStageRequest: (payload: TwoFactorLoginUserModel) => Promise<void>;
};

type VerificationData = {
  email: string;
  token: string;
};

type FormData = Pick<ConfirmEmailModel, 'email' | 'password'> & {
  passwordConfirmation: string;
};

const formSchema: ObjectSchema<FormData> = object({
  email: string().label('Email').trim().lowercase().required().email(),
  password: string().password(),
  passwordConfirmation: string()
    .label('Password')
    .trim()
    .required()
    .oneOf([ref('password')], 'Passwords should be the same'),
});

function SignUp({ loginStageRequest }: Props) {
  const navigate = useNavigate();
  const { verificationToken = '' } = useParams<RouteParams>();
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const { register, control, handleSubmit, formState, setValue } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const verificationData = useMemo<VerificationData | null>(() => {
    if (!verificationToken) return null;
    try {
      const decodedVerificationToken = window.atob(verificationToken);
      const { Email: email, base64passwordToken } = JSON.parse(decodedVerificationToken) || {};

      const token = window.atob(base64passwordToken);

      if (email && token) {
        return { email, token };
      } else {
        setServerErrorMessage('Invalid verification token');
      }
    } catch {
      setServerErrorMessage('Invalid verification token');
    }
    return null;
  }, [verificationToken]);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    if (!verificationData) return;
    try {
      const payload: ConfirmEmailModel = { email, password, token: verificationData.token };
      await updatePasswordByResetTokenFromEmail(payload);

      const loginStagePayload: TwoFactorLoginUserModel = {
        email,
        password,
        code: '',
      };
      await loginStageRequest(loginStagePayload);
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessage(formErrors[0]);
    }
  });

  useEffect(() => {
    if (verificationToken) return;

    navigate(RoutePath.login);
  }, [verificationToken, navigate]);

  useEffect(() => {
    if (!verificationData) return;
    setValue('email', verificationData.email);
  }, [setValue, verificationData]);

  return (
    <AuthLayout>
      <header className="has-text-centered">
        <h2 className="is-size-2 mb-4">Sign up</h2>
        <p className="is-size-5 has-text-weight-semibold px-4 pb-3 mb-5">
          You need to set a new password before you begin
        </p>
      </header>

      <form name="signUp" onSubmit={onSubmit} noValidate>
        <Field
          label="Email"
          field="input"
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          register={register('email')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!verificationData}
          readOnly
        />

        <Field
          label="New Password"
          field="input"
          type="password"
          placeholder="Enter your new password"
          autoComplete="new-password"
          register={register('password')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!verificationData}
          autoFocus
        />

        <Field
          label="Confirm New Password"
          field="input"
          type="password"
          placeholder="Confirm your new password"
          autoComplete="off"
          register={register('passwordConfirmation')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!verificationData}
        />

        <div className="field pt-4">
          <LoadingButton
            className="button is-interact is-fullwidth"
            type="submit"
            isLoading={formState.isSubmitting}
            disabled={!verificationData}
          >
            Set new password
          </LoadingButton>
          <ServerErrorMessage message={serverErrorMessage} />
        </div>
      </form>
    </AuthLayout>
  );
}

export default SignUp;
