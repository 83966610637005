import SuccessScreen from 'src/components/layout/SuccessScreen';

function ResetPasswordCompleted() {
  return (
    <SuccessScreen title="Reset Password">
      <p>You successfully reset your password</p>
    </SuccessScreen>
  );
}

export default ResetPasswordCompleted;
