import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { CheckRadio, ServerErrorMessage } from '@itm/shared-frontend/lib/components/forms';

import AuthLayout from 'src/components/layout/AuthLayout';

import { resetCurrentUserMFA } from 'src/api/auth/user';

import { ServerError } from 'src/types';

function ChangeMFA() {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const confirmChangeHandler = () => {
    setIsAgree((v) => !v);
  };

  const resetMFAClickHandler = async () => {
    setIsLoading(true);
    setServerErrorMessage('');
    try {
      await resetCurrentUserMFA();

      navigate(RoutePath.setupMFA);
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessage(formErrors[0]);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelClickHandler = () => {
    navigate(-1);
  };

  return (
    <AuthLayout>
      <h2 className="is-size-2 has-text-centered mb-4">MFA Update</h2>
      <p className="is-size-5 mb-4">You are about to change the MFA.</p>
      <p className="is-size-5 mb-4">
        Please note that by pressing the confirmation button, <strong>your current MFA will be reset</strong> and the
        current codes in the application will no longer be valid!
      </p>
      <div className="mb-5">
        <CheckRadio
          name="confirmation"
          type="checkbox"
          label="I confirm that I want to change my MFA"
          checked={isAgree}
          isBlock={false}
          onChange={confirmChangeHandler}
        />
      </div>

      <div className="is-flex is-justify-content-space-between">
        <LoadingButton
          className="button is-interact"
          type="button"
          onClick={resetMFAClickHandler}
          isLoading={isLoading}
          disabled={!isAgree}
        >
          Setup a New MFA
        </LoadingButton>
        <button className="button is-grey is-outlined" type="button" role="link" onClick={cancelClickHandler}>
          Cancel
        </button>
      </div>
      <ServerErrorMessage message={serverErrorMessage} />
    </AuthLayout>
  );
}

export default ChangeMFA;
