import classNames from 'classnames';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import {
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
  tenantBrandingTermsAndConditionsSelector,
} from 'src/store/selectors/tenantBrandingSelector';

import styles from './TermsOfUse.module.scss';

function TermsOfUse() {
  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);
  const brandingTermsAndConditions = useSelector(tenantBrandingTermsAndConditionsSelector);

  return (
    <main className={classNames(styles.Wrapper, 'is-flex is-flex-direction-column is-flex-grow-1')}>
      <div className="container is-fullwidth has-background-white px-2">
        <header className={styles.Header}>
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link className="navbar-item" to={RoutePath.root}>
                <img className="site-logo" src={brandingLogoUrl} alt={brandingLogoAlt} />
              </Link>
            </div>
          </nav>
        </header>
        <section className="columns">
          <article className="column is-10 is-offset-1">
            <div className="content">{parse(brandingTermsAndConditions)}</div>
          </article>
        </section>
        <footer className="pl-6 pb-6">
          <nav className="navbar" role="navigation" aria-label="footer navigation">
            <div className="navbar-brand">
              <Link className="navbar-item" to={RoutePath.root}>
                <img className="site-logo" src={brandingLogoUrl} alt={brandingLogoAlt} />
              </Link>
            </div>
          </nav>
        </footer>
      </div>
    </main>
  );
}

export default TermsOfUse;
