import qs from 'qs';
import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { history } from 'src/router';

import { AuthContext } from 'src/context/Auth';

import { useSelector, useDispatch } from 'src/store';
import { userPermissionsSelector } from 'src/store/selectors/userSelector';
import { selectedCompanyIdSelector } from 'src/store/selectors/companySelector';
import { clientPortalVersionSelector } from 'src/store/selectors/productInfoSelector';
import { selectCompany } from 'src/store/slices/companySlice';

import { useIsMount } from '@itm/shared-frontend/lib/hooks';
import { PageLoading } from '@itm/shared-frontend/lib/components';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { envDomain, ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import { getProductListAll, getProductListPromoted } from 'src/api/clientPortal/product';

import ModuleTileList from './components/ModuleTileList';
import TechnicalProblems from 'src/components/layout/TechnicalProblems';

import { ClientPortalRole, ProductResponse, ServerFormErrors, ServerError } from 'src/types';

export type Module = {
  id: string;
  title: string;
  logoUrl?: string;
  to: string | null;
  description: string | null;
  IconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

type TitleSorterArgument = {
  title: string;
};

type SearchParams = {
  companyId?: string;
  schemeId?: string;
};

const productToModuleMapper = ({ id, name, domainPrefix, url, description, logoUrl }: ProductResponse): Module => {
  const to = domainPrefix ? `${window.location.protocol}//${domainPrefix}.${envDomain}` : url;
  return {
    id,
    to,
    description,
    title: name || 'N/A',
    logoUrl: logoUrl || undefined,
  };
};

const titleSorter = (a: TitleSorterArgument, b: TitleSorterArgument) => a.title.localeCompare(b.title);

const hiddenProductDomainPrefixList = ['datauploader', 'webreporting', 'dashboards'];
const filterProductsByDomainPrefix = (productList: ProductResponse[]) =>
  productList.filter(({ domainPrefix, url }) =>
    domainPrefix
      ? !hiddenProductDomainPrefixList.includes(domainPrefix)
      : !hiddenProductDomainPrefixList.find((prefix) => Boolean(url && url.includes(prefix))),
  );

function Dashboard() {
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { clientPortalUserRole } = useContext(AuthContext);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const clientPortalVersion = useSelector(clientPortalVersionSelector);
  const userPermissionList = useSelector(userPermissionsSelector);
  const [allProductList, setAllProductList] = useState<ProductResponse[]>();
  const [promotedProductList, setPromotedProductList] = useState<ProductResponse[]>();
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { companyId = '' } = useMemo<SearchParams>(() => qs.parse(search.slice(1)), [search]);

  const fillAllProductList = useCallback(async () => {
    setAllProductList(undefined);
    setServerErrorMessages((v) => (v.length ? [] : v));
    try {
      const res = await getProductListAll();
      const displayingProductList = filterProductsByDomainPrefix(res.data);
      setAllProductList(displayingProductList);
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
      setAllProductList([]);
    }
  }, []);

  const fillPromotedProductList = useCallback(async () => {
    if (clientPortalUserRole === ClientPortalRole.SuperAdmin || clientPortalUserRole === ClientPortalRole.Support)
      return;
    setPromotedProductList(undefined);
    setServerErrorMessages((v) => (v.length ? [] : v));
    try {
      const res = await getProductListPromoted();
      const displayingProductList = filterProductsByDomainPrefix(res.data);
      setPromotedProductList(displayingProductList);
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
      setPromotedProductList([]);
    }
  }, [clientPortalUserRole]);

  const syncCompanyId = useCallback(() => {
    if (companyId !== selectedCompanyId) {
      if (isMount) {
        dispatch(selectCompany(companyId));
      } else {
        const query = history.location.search.slice(1);
        const searchParams = qs.parse(query);
        searchParams.companyId = selectedCompanyId;
        navigate({ search: qs.stringify(searchParams) }, { replace: true });
      }
    }
  }, [companyId, selectedCompanyId, isMount, dispatch, navigate]);

  const init = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([fillAllProductList(), fillPromotedProductList()]);
    setIsLoading(false);
  }, [fillAllProductList, fillPromotedProductList]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (
      clientPortalUserRole === ClientPortalRole.ClientAdmin ||
      clientPortalUserRole === ClientPortalRole.Manager ||
      clientPortalUserRole === ClientPortalRole.Analyst ||
      clientPortalUserRole === ClientPortalRole.Viewer
    ) {
      syncCompanyId();
    }
  }, [syncCompanyId, clientPortalUserRole]);

  const availableProductList = useMemo<ProductResponse[]>(() => {
    if (!allProductList) return [];
    return clientPortalUserRole === ClientPortalRole.SuperAdmin || clientPortalUserRole === ClientPortalRole.Support
      ? allProductList
      : allProductList.filter((product) => {
          const isProductAvailable = !!userPermissionList.find(
            (permission) => permission.productId === product.id && permission.companyId === selectedCompanyId,
          );
          return isProductAvailable;
        });
  }, [allProductList, clientPortalUserRole, selectedCompanyId, userPermissionList]);

  const exploreProductList = useMemo<ProductResponse[]>(() => {
    if (!promotedProductList) return [];
    return promotedProductList.filter(
      ({ id }) => !availableProductList.find((availableModule) => availableModule.id === id),
    );
  }, [promotedProductList, availableProductList]);

  const availableModuleList = useMemo<Module[]>(
    () =>
      availableProductList
        .map(productToModuleMapper)
        .filter(({ to }) => to)
        .sort(titleSorter),
    [availableProductList],
  );

  const exploreModuleList = useMemo<Module[]>(
    () =>
      exploreProductList
        .map(productToModuleMapper)
        .filter(({ to }) => to)
        .sort(titleSorter),
    [exploreProductList],
  );

  return (
    <div className="is-flex-grow-1 is-flex is-flex-direction-column">
      <h1 className="title mb-1">Welcome to your DataHub</h1>
      <hr />

      <ServerErrorMessages messages={serverErrorMessages} />
      <section className="is-flex-grow-1 mb-6">
        <div className="container">
          {isLoading ? (
            <div className="has-text-centered">
              <PageLoading />
            </div>
          ) : (
            <>
              {!!availableModuleList.length && (
                <section className="mb-6">
                  <ModuleTileList moduleList={availableModuleList} />
                  <hr />
                </section>
              )}
              {clientPortalUserRole !== ClientPortalRole.SuperAdmin &&
                clientPortalUserRole !== ClientPortalRole.Support &&
                !availableModuleList.length && (
                  <>
                    <TechnicalProblems
                      subtitle="It appears you do not have access to our modules"
                      description={[
                        'This could be because your access is still being configured. Please come back a bit later.',
                        'If you think you still see this page but you should have access to our modules.',
                        'Please contact the product support team to investigate.',
                      ]}
                    />
                    {!!exploreModuleList.length && <hr />}
                  </>
                )}
              {clientPortalUserRole !== ClientPortalRole.SuperAdmin &&
                clientPortalUserRole !== ClientPortalRole.Support &&
                !!exploreModuleList.length && (
                  <section>
                    <h2 className="title mb-5">Find out about our other modules</h2>
                    <ModuleTileList moduleList={exploreModuleList} companyId={selectedCompanyId} isRequestDemo />
                  </section>
                )}
            </>
          )}
        </div>
      </section>
      <p className="is-size-5 has-text-centered">{`DataHub v${clientPortalVersion}`}</p>
    </div>
  );
}

export default Dashboard;
