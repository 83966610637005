import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type ComponentProps = {
  to: string | null;
};

type Props = PropsWithChildren<ComponentProps> &
  React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>;

function LinkWrapper({ to, children, ...props }: Props) {
  const isExternalLink = to?.startsWith('http');
  return to ? (
    <>
      {isExternalLink ? (
        <a href={to} target="_blank" rel="noreferrer noopener" {...props}>
          {children}
        </a>
      ) : (
        <Link to={to} {...props}>
          {children}
        </Link>
      )}
    </>
  ) : (
    <div {...props}>{children}</div>
  );
}

export default LinkWrapper;
