import classNames from 'classnames';
import { useMemo, useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { AuthContext } from 'src/context/Auth';

import { useSelector, useDispatch } from 'src/store';
import { selectCompany } from 'src/store/slices/companySlice';
import {
  userCompanyOptionsSelector,
  selectedCompanyIdSelector,
  isLoadingCompanySelector,
} from 'src/store/selectors/companySelector';

import { dropdownFilterOption } from '@itm/shared-frontend/lib/utils';
import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';

import { ClientPortalRole, SelectOption } from 'src/types';

function CompanyFilter() {
  const { clientPortalUserRole, isLoggedIn } = useContext(AuthContext);
  const dispatch = useDispatch();
  const userCompaniesOptions = useSelector(userCompanyOptionsSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const isLoadingCompanies = useSelector(isLoadingCompanySelector);

  const selectedCompanyOption = useMemo(
    () => userCompaniesOptions.find((option) => option.value === selectedCompanyId) || null,
    [userCompaniesOptions, selectedCompanyId],
  );

  const dashboardRouteMatch = useMatch(`${RoutePath.dashboard}/*`);
  const companyViewDetailsRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyViewDetails);
  const companyViewSchemeRootRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyViewSchemeRoot);
  const companyViewSchemeListRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyViewSchemeList);
  const companyViewCreateSchemeRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyViewCreateScheme);
  const companyViewProductsRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyViewProducts);
  const companyViewSchemeAccessRouteMatch = useMatch(
    `${RoutePath.administrationOnboardingCompanyViewSchemeAccessRoot}/*`,
  );
  const companyEditDetailsRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyUpdateDetails);
  const companyEditSchemeRootRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyUpdateSchemeRoot);
  const companyEditSchemeListRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyUpdateSchemeList);
  const companyEditCreateSchemeRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyUpdateCreateScheme);
  const companyEditProductsRouteMatch = useMatch(RoutePath.administrationOnboardingCompanyUpdateProducts);
  const companyEditSchemeAccessRouteMatch = useMatch(
    `${RoutePath.administrationOnboardingCompanyUpdateSchemeAccessRoot}/*`,
  );

  const userCreatePersonalInformationRouteMatch = useMatch(
    `${RoutePath.administrationUserCreatePersonalInformation}/*`,
  );
  const onboardBulkUploadRouteMatch = useMatch(`${RoutePath.administrationOnboardingOnboardRoot}/*`);
  const dataAccessManageRouteMatch = useMatch(`${RoutePath.dataAccessManageRoot}/:tabName`);
  const dataRetentionPolicyListRouteMatch = useMatch(RoutePath.dataRetentionPolicyList);
  const dataRetentionPolicyCreateRootRouteMatch = useMatch(RoutePath.dataRetentionPolicyCreateRoot);
  const dataRetentionPolicyCreateDetailsRouteMatch = useMatch(RoutePath.dataRetentionPolicyCreateDetails);
  const dataRetentionLogsRouteMatch = useMatch(RoutePath.dataRetentionLogs);

  const isShowFilter = useMemo<boolean>(() => {
    if (
      !isLoggedIn ||
      (dashboardRouteMatch &&
        (clientPortalUserRole === ClientPortalRole.SuperAdmin || clientPortalUserRole === ClientPortalRole.Support))
    )
      return false;

    return Boolean(
      companyViewDetailsRouteMatch ||
        companyViewSchemeRootRouteMatch ||
        companyViewSchemeListRouteMatch ||
        companyViewCreateSchemeRouteMatch ||
        companyViewProductsRouteMatch ||
        companyViewSchemeAccessRouteMatch ||
        companyEditDetailsRouteMatch ||
        companyEditSchemeRootRouteMatch ||
        companyEditSchemeListRouteMatch ||
        companyEditCreateSchemeRouteMatch ||
        companyEditProductsRouteMatch ||
        companyEditSchemeAccessRouteMatch ||
        dashboardRouteMatch ||
        userCreatePersonalInformationRouteMatch ||
        onboardBulkUploadRouteMatch ||
        dataAccessManageRouteMatch ||
        dataRetentionPolicyListRouteMatch ||
        dataRetentionPolicyCreateRootRouteMatch ||
        dataRetentionPolicyCreateDetailsRouteMatch ||
        dataRetentionLogsRouteMatch,
    );
  }, [
    isLoggedIn,
    dashboardRouteMatch,
    clientPortalUserRole,
    companyViewDetailsRouteMatch,
    companyViewSchemeRootRouteMatch,
    companyViewSchemeListRouteMatch,
    companyViewCreateSchemeRouteMatch,
    companyViewProductsRouteMatch,
    companyViewSchemeAccessRouteMatch,
    companyEditDetailsRouteMatch,
    companyEditSchemeRootRouteMatch,
    companyEditSchemeListRouteMatch,
    companyEditCreateSchemeRouteMatch,
    companyEditProductsRouteMatch,
    companyEditSchemeAccessRouteMatch,
    userCreatePersonalInformationRouteMatch,
    onboardBulkUploadRouteMatch,
    dataAccessManageRouteMatch,
    dataRetentionPolicyListRouteMatch,
    dataRetentionPolicyCreateRootRouteMatch,
    dataRetentionPolicyCreateDetailsRouteMatch,
    dataRetentionLogsRouteMatch,
  ]);

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };

  return (
    <>
      {isShowFilter && (
        <div className="is-flex is-justify-content-center pt-5">
          <div className="control is-fullwidth">
            <Select
              className={classNames('react-select', { 'is-loading': isLoadingCompanies })}
              classNamePrefix="react-select"
              value={selectedCompanyOption}
              options={userCompaniesOptions}
              aria-label="Company name"
              placeholder={
                userCompaniesOptions.length || isLoadingCompanies ? 'Company Name' : 'No available companies'
              }
              onChange={companyChangeHandler}
              isDisabled={isLoadingCompanies || !userCompaniesOptions.length}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={true}
              filterOption={dropdownFilterOption}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyFilter;
