import qs from 'qs';
import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios';
import { history, RoutePath } from 'src/router';

import { identity, logout } from 'src/context/Auth';

export function updateRequestConfigByAccessToken<T extends AxiosRequestConfig>(accessToken: string, config: T): T {
  const authHeader = `Bearer ${accessToken}`;

  if (!config.headers) config.headers = {} as AxiosHeaders;
  if (accessToken) config.headers.Authorization = authHeader;
  return config;
}

const createAxiosInstance = (url: string) => {
  const api = axios.create({
    baseURL: url,
    paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const { response } = error;

      if (response && response.status === 401) {
        try {
          await logout(true);
          history.push(RoutePath.login);
        } catch {}
      }
      if (response && response.status === 403 && !response.data) {
        response.data = "You don't have permission to access.";
      }
      return Promise.reject(error);
    },
  );

  api.interceptors.request.use((config) => {
    if (!config.headers?.Authorization) {
      const { accessToken } = identity.getCookieAuthState() || {};
      if (accessToken) return updateRequestConfigByAccessToken(accessToken, config);
    }

    return config;
  });

  return api;
};

export default createAxiosInstance;
