import qs from 'qs';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { previousLocationSelector } from 'src/store/selectors/routerSelector';

import { tenantDomain } from '@itm/shared-frontend/lib/utils';

type Props = {
  shouldRedirect: boolean;
  setShouldRedirect: React.Dispatch<React.SetStateAction<boolean>>;
};

function SignInRedirect({ shouldRedirect, setShouldRedirect }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = useSelector(previousLocationSelector);

  useEffect(() => {
    if (!shouldRedirect) return;
    setShouldRedirect(false);

    // ClientPortal
    if (
      previousLocation.pathname &&
      previousLocation.pathname !== RoutePath.root &&
      !previousLocation.pathname.startsWith(RoutePath.authRoot)
    ) {
      navigate(previousLocation.pathname);
      return;
    }

    // Other products
    const query = location.search.slice(1);
    const searchParams = qs.parse(query);

    if (searchParams.redirectTo) {
      const href = Array.isArray(searchParams.redirectTo)
        ? searchParams.redirectTo[0].toString()
        : searchParams.redirectTo.toString();
      const url = new URL(href);

      if (url.hostname.endsWith(tenantDomain)) {
        window.location.href = href;
      } else {
        navigate(RoutePath.root);
      }
      return;
    }

    // Default
    navigate(RoutePath.root);
  }, [location.search, navigate, previousLocation.pathname, setShouldRedirect, shouldRedirect]);

  return null;
}

export default SignInRedirect;
